.Contacts {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 100vh;
  color: var(--footer-text-color);
  text-align: center;
  font-size: 1.7vw;
}
.Contacts .get-in-touch {
  position: relative;
  top: 1px;
}

.Contacts .get-in-touch .resume {
  margin: 0 0 250px;
  font-size: 2.5vw;
}

.Contacts .get-in-touch span {
  color: var(--first-accent-color);
  font-weight: 700;
}

.Contacts .get-in-touch .love {
  color: var(--second-accent-color);
  font-weight: 700;
}

.Contacts .get-in-touch p {
  color: var(--main-text-color);
  position: relative;
  top: 8vw;
}

.Contacts .contact-content {
  display: flex;
  flex-direction: column;
  height: 65vh;
  background: var(--first-accent-color);
}

.Contacts .email-info {
  font-size: 2.9vw;
  margin: 15vh 0 5.4vh;
}

.Contacts .email-info a {
  color: var(--footer-text-color);
}

/* Underline hover email*/
.Contacts .email-info .email {
  background-size: 0;
  transition: background-size 250ms ease-in-out;
}

.Contacts .email-info .email:hover {
  background: linear-gradient(
    transparent 50%,
    var(--theme-highlight-color) 50%,
    var(--theme-highlight-color) 85%,
    transparent 85%,
    transparent 100%
  );
  background-repeat: no-repeat;
  background-size: 100%;
}

.Contacts .contact-icons {
  display: inline-flex;
  align-self: center;
}
.Contacts .contact-icons a {
  color: var(--footer-text-color);
  font-size: 4vw;
  margin: 0 2.8vw;
  align-self: center;
  display: inline-flex;
  text-decoration: none;
}
.Contacts .contact-icons img {
  width: 3.5vw;
}

.Contacts .copyright {
  font-size: 1vw;
  margin: auto 0 10vh;
  text-align: center;
}
.Contacts .copyright a {
  color: var(--second-accent-color);
}

.Contacts .email {
  font-size: 2.5vw;
}

@media screen and (orientation: portrait) {
  .Contacts {
    font-size: 2vh;
  }
  .Contacts .email-info {
    font-size: 4vh;
    margin-left: 5vw;
    margin-right: 5vw;
  }
  .Contacts .contact-icons a {
    font-size: 5vh;
  }
  .Contacts .contact-icons img {
    width: 4.4vh;
  }
  .Contacts .copyright {
    font-size: 1.8vh;
  }
  .Contacts .email {
    font-size: 3.5vh;
  }
}

@media (max-width: 1200px) {
  .Contacts .contact-me {
    margin: 50px 40px;
    padding: 50px 40px;
  }
  .Contacts .get-in-touch .resume,
  .Contacts .contact-me h3,
  .Contacts .email-block {
    font-size: 24px;
  }

  .Contacts .contact-me p {
    font-size: 14px;
  }

  .Contacts .contact-me a {
    font-size: 18px;
  }

  .Contacts .contact-icons-block {
    font-size: 20px;
  }
  .Contacts .contact-icons-block a {
    width: 45px;
    height: 45px;
  }
  .Contacts .copyright {
    font-size: 12px;
  }
}
