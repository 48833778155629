.Experience h3 {
  text-align: center;
}

.Experience .experience-block {
  margin: 90px 0;
  font-size: 1.3vw;
}
.Experience li i {
  color: var(--first-accent-color);
  margin-right: 10px;
}
.Experience .header {
  margin: 150px 0 60px;
  display: flex;
  justify-content: center;
}
.Experience .job {
  color: var(--second-accent-color);
  text-transform: uppercase;
  font-weight: bold;
}
.Experience .years {
  position: absolute;
  left: 4.16vw;
}
.Experience .skill-header {
  font-size: 1.5vw;
  color: var(--first-accent-color);
  font-weight: bold;
}
@media (max-width: 1200px) {
  .Experience .experience-block {
    font-size: 14px;
  }
  .Experience .skill-header {
    font-size: 16px;
  }
}
@media (max-width: 767.98px) {
  .Experience .years {
    position: static;
    margin-right: 30px;
  }
  .Experience .header {
    margin: 80px 0 40px;
  }
}
