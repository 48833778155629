.Project {
  min-height: 100vh;
  padding: 50px 0;
}
.Project h5 {
  font-size: 2.9vw;
  color: var(--first-accent-color);
  margin-bottom: 2.6vw;
}

.Project h6 {
  font-family: "CynthoNext-Light", Verdana, Geneva, Tahoma, sans-serif;
  font-size: 1vw;
  margin-bottom: 0.53vw;
}

.Project .project-img {
  width: 45vw;
  height: 27vw;
  border-radius: 1.25vw 1.25vw 0.62vw 0.62vw;
  box-shadow: 0px 0.62vw 0.62vw rgba(0, 0, 0, 0.25);
  align-self: center;
}

.Project .project-info {
  width: 35vw;
  align-self: center;
}

.Project .project-description,
.Project .tech-features {
  font-size: 1.7vw;
  line-height: 130%;
}
.Project .project-description {
  margin-bottom: 1.56vw;
}
.Project .tech-features {
  color: var(--second-accent-color);
}

@media screen and (orientation: portrait) {
  .Project {
    min-height: fit-content;
  }
}
@media (max-width: 1200px) {
  .Project h5 {
    font-size: 38px;
  }

  .Project h6 {
    font-size: 12px;
  }

  .Project .project-description,
  .Project .tech-features {
    font-size: 14px;
  }
  .Project .project-info {
    width: 42vw;
    align-self: center;
  }
}
@media (max-width: 767.98px) {
  .Project .project-info {
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }

  .Project .project-img {
    width: 80%;
    height: 80%;
  }

  .Project .project-description {
    margin-bottom: 16px;
  }
}
