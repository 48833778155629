/**
 * @license
 * MyFonts Webfont Build ID 5040362, 2022-06-02T06:09:24-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: AlbulaPro-Bold by Serpentype
 * URL: https://www.myfonts.com/fonts/serpentype/albula-pro/bold/
 * Copyright: Copyright © 2018 by Silvio Meier. All rights reserved.
 * 
 * 
 * 
 * © 2022 MyFonts Inc
*/

@font-face {
  font-family: "AlbulaPro-Bold";
  src: url("webFonts/AlbulaProBold/font.woff2") format("woff2"),
    url("webFonts/AlbulaProBold/font.woff") format("woff");
}

:root {
  --main-text-color: #13293d;
  --footer-text-color: #fcfcfd;
  --first-accent-color: #057f84;
  --second-accent-color: #ffb188;
  --first-switch-accent-color: #057f84;
  --second-switch-accent-color: #ffb188;
  --theme-background: #fcfcfd;
  --main-gray-color: #d9d9d9;
  --theme-highlight-color: #ffb188;
  --link-color: #448aff;
}

body {
  font-family: "Nunito Sans", Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 400;
  margin: 0;
  color: var(--main-text-color);
  background: var(--theme-background);
  transition: color 0.3s ease, background 0.3s ease;
}

body::before {
  color: var(--main-text-color);
  background: var(--theme-background);
  transition: color 0.3s ease, background 0.3s ease;
}
h3,
h4,
h5,
.name,
.profession,
.email {
  font-family: "AlbulaPro-Bold", Verdana, Geneva, Tahoma, sans-serif;
}

.AboutMe,
.Projects,
.Experience {
  min-height: 100vh;
  padding: 96px 4.16vw;
  height: fit-content;
}

h3 {
  font-size: 2.9vw;
  margin-top: 90px;
  text-align: center;
}
ul {
  list-style: none;
  padding: 0;
}

@media screen and (orientation: portrait) {
  .AboutMe,
  .Projects,
  .Experience,
  .Project {
    min-height: fit-content;
  }
}

@media (max-width: 767.98px) {
  h3 {
    font-size: 38px;
    margin-top: 50px;
    text-align: center;
  }
  .AboutMe,
  .Projects,
  .Experience {
    padding: 45px 30px;
  }
}
