.Projects .portfolio-title {
  font-size: 1.3vw;
  text-align: center;
  margin-bottom: 40px;
}

.Projects .portfolio-title-description {
  font-size: 2.9vw;
  text-align: center;
}
@media (max-width: 1200px) {
  .Projects .portfolio-title {
    font-size: 14px;
  }

  .Projects .portfolio-title-description {
    font-size: 32px;
  }
}
