.ThemeSwitch {
  width: 30px;
  height: 32px;
  margin-right: 2vw;
  align-self: center;
}
.ThemeSwitch button {
  background: none;
  border: none;
  width: inherit;
  height: 100%;
  padding: 5px;
}
.ThemeSwitch button i {
  font-size: 18px;
}

.ThemeSwitch .fixed {
  position: absolute;
  top: -99vh;
  right: 4.16vw;
}

.ThemeSwitch input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
}

.ThemeSwitch label {
  display: block;
  position: relative;
  width: 48px;
  height: 24px;
  background: var(--main-gray-color);
  border-radius: 24px;
  cursor: pointer;
}

.ThemeSwitch label:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 20px;
  height: 20px;
  background: var(--theme-highlight-color);
  border-radius: 20px;
  transition: 0.3s;
}

.ThemeSwitch input:checked + label:after {
  left: calc(100% - 2px);
  transform: translateX(-100%);
  background: var(--theme-highlight-color);
}

.ThemeSwitch label:active:after {
  width: 30px;
}

.ThemeSwitch .icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  margin: 0 5px;
}

.ThemeSwitch .icons i {
  height: 15px;
  width: 15px;
}
.ThemeSwitch .fa-sun {
  color: #ff7b54;
}
.ThemeSwitch .fa-moon {
  color: #13293d;
}
