.Profile {
  text-transform: uppercase;
  margin: 120px 0;
  font-size: 1vw;
}

.Profile li {
  margin-bottom: 8px;
}
.Profile .line {
  margin-bottom: 60px;
}
.Profile h4 {
  text-align: right;
  color: var(--second-switch-accent-color);
  font-size: 1vw;
}
.Profile .accent {
  color: var(--first-switch-accent-color);
}
.Profile i {
  margin-right: 10px;
}

@media (max-width: 1200px) {
  .Profile {
    font-size: 12px;
    margin: 90px 0 0;
  }
  .Profile h4 {
    font-size: 12px;
  }
}
