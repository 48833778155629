.navbar,
.container-fluid,
.navbar-brand {
  padding: 0;
}

.navbar {
  box-shadow: 0px 7px 9px rgb(0 0 0 / 15%);
  background: var(--theme-background);
  transition: background 0.3s ease;
}

.navbar::before {
  background: var(--theme-background);
  transition: background 0.3s ease;
}
.navbar-brand svg {
  width: 48px;
  height: 45px;
  display: inline-block;
  margin-left: 3.5vw;
  fill: var(--main-text-color);
  vertical-align: baseline;
}
.reverse {
  display: flex;
}
.navbar-nav {
  margin-right: 2vw;
  align-self: flex-end;
  align-items: center;
}

.nav-link {
  width: fit-content;
  color: var(--main-text-color) !important;
  margin: 0 16px;
  padding: 4px 8px !important;
}

.active {
  font-weight: 900;
}

.navbar-toggler {
  width: 48px;
  margin-right: 3.5vw;
  padding: 5px;
  border: none;
}

.navbar-size-replace {
  height: 61px;
}

@media (max-width: 767px) {
  .reverse {
    display: flex;
    flex-direction: row-reverse;
  }
}
